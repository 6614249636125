import { Fragment, useRef, useCallback, useEffect, useState } from "react";
import axios from "axios";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import ServiceItem from "../components/service-item";
// import Swiper core and required modules
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
// import Swiper bundle with all modules installed
import { Swiper, SwiperSlide } from "swiper/react";
// import styles bundle
import "swiper/css/bundle";
import FAQItem from "../components/faq-item";

export default function Home() {
  const [page, setPage] = useState({});
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState({});
  const [pageMedia, setPageMedia] = useState([]);
  const [banners, setBanners] = useState([]);
  const [bannerMedia, setBannerMedia] = useState([]);

  const [services, setServices] = useState([]);
  const [servicesMedia, setServicesMedia] = useState([]);

  const whyPartner = [
    {
      title: "Attitude",
      description:
        "We maintain the best attitude towards work and clients, fostering positive relationships and a collaborative environment.",
    },
    {
      title: "Service Delivery",
      description:
        "Our commitment to service delivery is unwavering; our priority is ensuring that clients receive the utmost satisfaction.",
    },
    {
      title: "Experience",
      description:
        "With years of accumulated knowledge, we bring expertise and reliability to every project and interaction.",
    },
  ];

  useEffect(() => {
    setLoading(true);
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "home"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        CONTENT_ROUTES.POST_PAGE(
          "/posts",
          "&_embed&order_by=menu_order&order=desc&per_page=100"
        )
      )
      .then((res) => {
        setPosts(res.data);
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  useEffect(() => {
    setLoading(true);
    //Get banners
    axios
      .get(CONTENT_ROUTES.BANNERS("?order_by=menu_order&order=asc"))
      .then((res) => {
        setBanners(res.data);

        //Get banner media
        let media = [];

        res.data?.map((banner, i) => {
          if (banner?.featured_media)
            axios
              .get(CONTENT_ROUTES.MEDIA_BY_ID(banner?.featured_media))
              .then((res) => {
                media[i] = res.data;
                setBannerMedia((prevState) => [...prevState, media[i]]);
              });
          return banner?.featured_media;
        });
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  useEffect(() => {
    setLoading(true);

    //Get team
    axios
      .get(
        CONTENT_ROUTES.SERVICES("?order_by=menu_order&order=asc&per_page=100")
      )
      .then((res) => {
        setServices(res.data);

        //Get services media
        let media = [];

        res.data?.map((service, i) => {
          if (service?.featured_media)
            axios
              .get(CONTENT_ROUTES.MEDIA_BY_ID(service?.featured_media))
              .then((res) => {
                media[i] = res.data;
                setServicesMedia((prevState) => [...prevState, media[i]]);
              });
          return service?.featured_media;
        });
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Header />
      <main>
        <section>
          <div className="relative banner">
            <Swiper
              ref={sliderRef}
              modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              direction={"horizontal"}
              loop={true}
              speed={1000}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
            >
              {banners?.map((banner, i) => (
                <SwiperSlide key={i} className="swiper-slide">
                  <div
                    className="image"
                    style={{
                      backgroundImage: `url(${
                        bannerMedia?.find(
                          (media) => media.id === banner?.featured_media
                        )?.source_url
                      })`,
                    }}
                  ></div>

                  <div className="absolute left-0 top-0 z-10 w-full h-full bg-gradient-to-r from-black md:to-50% to-100% opacity-50"></div>

                  <div className="container mx-auto z-20">
                    <div className="md:w-[500px] md:ml-10 w-full">
                      <h2 className="text-white text-4xl">
                        {banner?.title?.rendered}
                      </h2>
                      {banner?.acf?.banner_cta?.title && (
                        <NavLink
                          to={banner?.acf?.banner_cta?.url}
                          className="px-5 py-2 text-white bg-[#00bbe3] inline-block rounded-full mt-10"
                        >
                          {banner?.acf?.banner_cta?.title}
                        </NavLink>
                      )}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>

            <div onClick={handlePrev} className="swiper-button-prev p-2">
              <i className="fi fi-br-angle-left text-4xl text-[#00bbe3]"></i>
            </div>
            <div onClick={handleNext} className="swiper-button-next p-2">
              <i className="fi fi-br-angle-right text-4xl text-[#00bbe3]"></i>
            </div>
          </div>
        </section>

        <section className="relative bg-[#efeae3]">
          <div className="container mx-auto lg:flex">
            <div className="md:w-1/2 flex-1 content-center">
              <h1
                className="pb-8 text-5xl font-bold text-gray-950"
                dangerouslySetInnerHTML={{
                  __html: page?.content?.rendered
                    ?.replace(/<p.*?>*?<\/p>/g, "")
                    ?.replace(/<h2.*?>/g, ""),
                }}
              />
              <div
                className="mb-5 text-gray-950"
                dangerouslySetInnerHTML={{
                  __html: page?.content?.rendered
                    ?.replace(/<h2.*?>*?<\/h2>/g, "")
                    ?.replace(/<p>/g, "<p class='pb-2'>"),
                }}
              />

              <NavLink
                to={WEB_ROUTES.ABOUT}
                className="bg-[#21356a] px-5 py-2 text-white hover:bg-[#00bbe3] inline-block rounded-full"
              >
                About Mokoni Investment Diversities
              </NavLink>
            </div>

            <div className="md:w-1/2 pt-8 flex justify-center content-end">
              <img
                className="w-3/5 object-cover"
                src={
                  pageMedia?.find((media) => media?.id === page?.featured_media)
                    ?.source_url
                }
                alt={page?.title?.rendered}
              />
            </div>
          </div>
        </section>

        <section className=" py-20">
          <div className="container mx-auto">
            <div className="max-full">
              <h2 className="text-4xl font-bold tracking-tight text-gray-900">
                Our Services
              </h2>
              <p className="mt-2">
                Our services encompass a broad range, including but not limited
                to the following:
              </p>
            </div>
            <section className="pb-16 mt-10">
              <div className="container mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-4 rounded-lg divide-x-2 divide-gray-950 border-2 border-gray-950">
                  {services.map((service, i) => (
                    <Fragment key={i}>
                      <ServiceItem
                        service={service}
                        serviceMedia={servicesMedia?.find(
                          (media) => media.id === service?.featured_media
                        )}
                      />
                    </Fragment>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>

        <section className="pb-20">
          <div className="container mx-auto">
            <div className="grid grid-cols-1 gap-12 md:grid-cols-2 lg:gap-20">
              <div className="order-2  overflow-hidden md:order-1">
                <img
                  src="/images/why-partner-with-us.webp"
                  width="600"
                  height="400"
                  className="h-auto w-full"
                />
              </div>

              <div className="order-1 md:order-2">
                <div className="mb-6">
                  <h2 className="text-4xl font-bold tracking-tight text-gray-900">
                    Why partner with us?
                  </h2>
                  <p className="mt-2">
                    At Mokoni, we have a client-focused approach and a
                    commitment to excellence; we help you navigate challenges,
                    optimise operations, and achieve long-term success.
                  </p>
                </div>

                <div className="bg-white">
                  <div className="mx-auto max-w-7xl">
                    <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                      {whyPartner.map((faq, i) => (
                        <Fragment key={i}>
                          <FAQItem faq={faq} />
                        </Fragment>
                      ))}
                    </div>
                  </div>
                </div>

                <NavLink
                  to={WEB_ROUTES.SERVICES}
                  className="bg-[#21356a] px-5 py-2 text-white hover:bg-[#00bbe3] inline-block rounded-full mt-4"
                >
                  More on partnering with us
                </NavLink>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-[#00bbe3] py-16">
          <div className="container mx-auto">
            <div className="global-container">
              <div className="mb-10 text-center ">
                <div className="mx-auto">
                  <h2 className="text-4xl font-bold tracking-tight text-[#21356a]">
                    Positive feedback from our clients
                  </h2>
                </div>
              </div>

              <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 text-white">
                <div className="flex flex-col gap-y-3 p-[30px]">
                  <div className=" flex gap-x-1 text-yellow-500 text-2xl">
                    <i className="fi fi-ss-star"></i>
                    <i className="fi fi-ss-star"></i>
                    <i className="fi fi-ss-star"></i>
                    <i className="fi fi-ss-star"></i>
                    <i className="fi fi-ss-star"></i>
                  </div>
                  <p>
                    "Working with the Mokoni team has been a game-changer for
                    our business. Their unmatched expertise in financial
                    management and process improvement instils confidence and
                    security. I highly recommend their services!"
                  </p>
                  <div className="flex items-center gap-x-4">
                    <div className="flex flex-col gap-y-1">
                      <span className="block text-lg font-semibold leading-[1.6]">
                        Mr M. Makaulule
                      </span>
                      <span className="block text-sm font-light leading-[1.4]">
                        Director at Bluevine Data
                      </span>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-y-3 p-[30px]">
                  <div className=" flex gap-x-1 text-yellow-500 text-2xl">
                    <i className="fi fi-ss-star"></i>
                    <i className="fi fi-ss-star"></i>
                    <i className="fi fi-ss-star"></i>
                    <i className="fi fi-ss-star"></i>
                    <i className="fi fi-ss-star"></i>
                  </div>
                  <p>
                    "The professionalism and support we received on our
                    accounting and tax were exceptional. They guided us every
                    step of the way, ensuring our financial management was
                    streamlined and compliant."
                  </p>
                  <div className="flex items-center gap-x-4">
                    <div className="flex flex-col gap-y-1">
                      <span className="block text-lg font-semibold leading-[1.6]">
                        Mr P. Moeng
                      </span>
                      <span className="block text-sm font-light leading-[1.4]">
                        Managing Director at Kaitoma Collective
                      </span>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-y-3 p-[30px]">
                  <div className=" flex gap-x-1 text-yellow-500 text-2xl">
                    <i className="fi fi-ss-star"></i>
                    <i className="fi fi-ss-star"></i>
                    <i className="fi fi-ss-star"></i>
                    <i className="fi fi-ss-star"></i>
                    <i className="fi fi-ss-star"></i>
                  </div>
                  <p>
                    "We've seen significant improvements in our processes thanks
                    to their tailored solutions and expert advice. The team is
                    knowledgeable, responsive, and truly invested in our
                    success."
                  </p>
                  <div className="flex items-center gap-x-4">
                    <div className="flex flex-col gap-y-1">
                      <span className="block text-lg font-semibold leading-[1.6]">
                        Mr K. Quansah
                      </span>
                      <span className="block text-sm font-light leading-[1.4]">
                        Managing Director at The AppLab
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
