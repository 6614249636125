import { useState, useEffect } from "react";
import { useMatch } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import axios from "axios";

export default function TeamSingle() {
  const [team, setTeam] = useState({});
  const singleTeam = useMatch(WEB_ROUTES.TEAM + "/:team");
  const [loading, setLoading] = useState({});
  const [page, setPage] = useState({});
  const [pageMedia, setPageMedia] = useState([]);
  const [teamMedia, setTeamMedia] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "team"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });

        //Get team
        axios
          .get(CONTENT_ROUTES.TEAM("?slug=" + singleTeam?.params?.team))
          .then((res) => {
            let teamResponse = res.data?.length ? res.data[0] : {};
            setTeam(teamResponse);

            //Get team media
            let media = [];

            res.data?.map((member, i) => {
              if (member?.featured_media)
                axios
                  .get(CONTENT_ROUTES.MEDIA_BY_ID(member?.featured_media))
                  .then((res) => {
                    media[i] = res.data;
                    setTeamMedia((prevState) => [...prevState, media[i]]);
                  });
              return member?.featured_media;
            });
          })
          .catch((err) => {})
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, [singleTeam]);

  return (
    <>
      <Header />
      <main>
        <section className="bg-white pt-16 pb-8">
          <div className="container mx-auto md:px-0 px-8">
            <div className="text-base leading-7 text-gray-700 w-full">
              <h1 className="pb-3 text-5xl font-semibold capitalize">
                {team?.title?.rendered}
              </h1>
              <h2 className="text-2xl font-semibold text-[#00bbe3]">
                {team?.acf?.team_member_designation}
              </h2>
            </div>
          </div>
        </section>

        <section className="bg-white pb-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="mx-auto md:flex md:gap-x-10">
              <div className="md:w-1/4 w-full md:mb-0 mb-5">
                <img
                  src={
                    teamMedia?.find(
                      (media) => media.id === team?.featured_media
                    )?.source_url
                  }
                  alt={team?.title?.rendered}
                  className="object-cover"
                />
              </div>
              <div className="w-3/4">
                <div className="text-base leading-7 text-gray-700">
                  <p>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: team?.content?.rendered?.replace(
                          /<p.*?>/g,
                          "<p class='mb-5'>"
                        ),
                      }}
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
