import { useState } from "react";
import { WEB_ROUTES } from "../routes/web-routes";
import { NavLink } from "react-router-dom";

export default function TeamItem({ member, memberMedia }) {
  return (
    <>
      <div className="group relative transition-all duration-300 ease-in-out">
        <div className="relative">
          <img
            src={memberMedia?.source_url}
            alt={member?.title?.rendered}
            className="object-cover"
          />
        </div>
        <div className="absolute bottom-0 left-0 p-6 z-50 bg-gray-950/80 w-full">
          <h3 className="mb-2 text-white font-bold text-2xl">
            <NavLink to={WEB_ROUTES.TEAM + "/" + member?.slug}>
              {member?.title?.rendered}{" "}
              {member?.acf?.team_member_qualifications}
            </NavLink>
          </h3>
          <p className="mt-2 mb-2 duration-300 text-white">
            {member?.acf?.team_member_designation}
          </p>
          <NavLink
            to={WEB_ROUTES.TEAM + "/" + member?.slug}
            className="relative inline-block h-[30px] w-[30px] duration-300"
          >
            <img
              src="/images/arrow-right-orange.svg"
              alt="arrow-right-black"
              width="30"
              height="30"
            />
          </NavLink>
        </div>
      </div>
    </>
  );
}
