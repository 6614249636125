import { useState } from "react";
import { WEB_ROUTES } from "../routes/web-routes";
import { NavLink } from "react-router-dom";

export default function ServiceItem({ service, serviceMedia }) {
  return (
    <>
      <div className="group p-10 transition-all duration-300 ease-in-out hover:bg-[#21356a]">
        <div className="relative mb-4 h-[70px] w-[70px]">
          <span
            dangerouslySetInnerHTML={{
              __html: service?.acf?.service_icon,
            }}
          />
        </div>
        <div className="mt-4 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-6">
          <h3 className="mb-4 group-hover:text-white font-bold text-2xl">
            <NavLink
              to={WEB_ROUTES.SERVICES + "/" + service?.slug}
              className="hover:text-[#00bbe3]"
            >
              {service?.title?.rendered}
            </NavLink>
          </h3>
          <p className="mt-2 mb-8 duration-300 group-hover:text-white">
            {service?.acf?.service_excerpt}
          </p>
          <NavLink
            to={WEB_ROUTES.SERVICES + "/" + service?.slug}
            className="relative inline-block h-[30px] w-[30px] duration-300"
          >
            <img
              src="/images/arrow-right-orange.svg"
              alt="arrow-right-black"
              width="30"
              height="30"
            />
          </NavLink>
        </div>
      </div>
    </>
  );
}
