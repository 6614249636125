import { useContext } from "react";
import { OpenCloseContactContext } from "../contexts/contact";
import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";

export default function Footer() {
  let { setOpenCloseContact } = useContext(OpenCloseContactContext);
  return (
    <footer className="bg-[#21356a]">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto container pb-16 pt-16 md:px-0 px-8">
        <NavLink to={WEB_ROUTES.HOME}>
          <img
            className="mb-2 w-32"
            src="/images/midiversities-logo-white.png"
            alt="Mokoni Investment Diversities"
          />
        </NavLink>
        <div className="md:grid md:grid-cols-5 md:gap-8">
          <div className="text-white col-span-2">
            <div className="max-w-md">
              From its inception, Mokoni has recognised that its client's growth
              and success hinges upon Mokoni's commitment to delivering
              unparalleled quality services.
            </div>
          </div>
          <div className="mt-10 md:mt-0 col-span-1">
            <h3 className="font-semibold text-[#00bbe3]">What we do</h3>
            <ul className="mt-6 space-y-4">
              <li>
                <NavLink
                  to={WEB_ROUTES.SERVICES_TAXATION}
                  className="text-white"
                >
                  Taxation services
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={WEB_ROUTES.SERVICES_ACCOUNTING}
                  className="text-white"
                >
                  Accounting services
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={WEB_ROUTES.SERVICES_ADVISORY}
                  className="text-white"
                >
                  Advisory services
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={WEB_ROUTES.SERVICES_TRAINING}
                  className="text-white"
                >
                  Training and accreditation services
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="mt-10 md:mt-0 col-span-1">
            <h3 className="font-semibold text-[#00bbe3]">Affiliations</h3>
            <ul className="mt-6 space-y-4">
              <li>
                <a
                  href="https://www.thesait.org.za/"
                  target="_blank"
                  className="text-white"
                >
                  The South African Institute of Taxation (SAIT)
                </a>
              </li>
              <li>
                <a
                  href="https://www.saica.org.za/"
                  target="_blank"
                  className="text-white"
                >
                  The South African Institute of Chartered Accountants (SAICA)
                </a>
              </li>
            </ul>
          </div>

          <div className="mt-10 md:mt-0 col-span-1">
            <h3 className="font-semibold text-[#00bbe3]">Get in touch</h3>
            <ul className="mt-6 space-y-4">
              <li>
                <NavLink to={WEB_ROUTES.CONTACT} className="text-white">
                  Contact us
                </NavLink>
              </li>
              <li>
                <div className="group md:mt-0 mt-8">
                  <a
                    href="https://za.linkedin.com/company/mokoni-investment-diversities"
                    target="_blank"
                    rel="noreferrer"
                    className="text-neutral-400 group-hover:text-[#00bbe3]"
                  >
                    <h3 className="text-white float-left mr-2">
                      Follow us on LinkedIn
                    </h3>

                    <svg
                      className="h-6 w-6 float-left"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" />
                    </svg>

                    <div className="clear-both"></div>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="py-10 border-t border-white/10">
        <div className="mx-auto container md:space-x-4 space-x-0">
          <div className="text-neutral-400 md:float-left float-left mb-0 md:mt-0 mt-4">
            &copy; Copyright {new Date().getFullYear()}. All rights reserved.
            Mokoni Investment Diversities
          </div>

          <NavLink
            to={WEB_ROUTES.PRIVACY_POLICY}
            className=" text-neutral-400 md:float-right hover:text-white md:inline block"
          >
            Privacy policy
          </NavLink>
          <NavLink
            to={WEB_ROUTES.TERMS}
            className=" text-neutral-400 md:float-right hover:text-white md:inline block"
          >
            Terms of use
          </NavLink>

          <div className="clear-both"></div>
        </div>
      </div>
    </footer>
  );
}
