import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import FormContact from "../components/form-contact";

export default function Contact() {
  const [page, setPage] = useState({});
  const [loading, setLoading] = useState({});
  const [pageMedia, setPageMedia] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "contact"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);
        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  return (
    <>
      <Header />
      <main>
        <section className="bg-white pt-16 pb-8">
          <div className="container mx-auto">
            <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div>
                <div className="text-base leading-7 text-gray-700 md:px-0 px-8">
                  <h1 className="pb-5 text-5xl font-semibold">
                    {page?.title?.rendered}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white pb-8">
          <div className="container mx-auto md:px-0 px-8">
            <div className="text-base leading-7 text-gray-700">
              <div className="md:flex gap-x-10">
                <article className="w-2/4 md:mb-0 mb-8">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: page?.content?.rendered?.replace(
                        /<p.*?>/g,
                        "<p class='mb-5'>"
                      ),
                    }}
                  />
                </article>
                <FormContact />
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
