import { useState, useEffect, useContext, Fragment } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import { OpenCloseContactContext } from "../contexts/contact";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { CONTENT_ROUTES } from "../routes/content-routes";
import FormContact from "./form-contact";

export default function Header() {
  const [activeTab, setActiveTab] = useState(0);
  let { openCloseContact, setOpenCloseContact } = useContext(
    OpenCloseContactContext
  );
  const [open, setOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (openCloseContact !== undefined && openCloseContact !== null)
      setOpen(openCloseContact);
  }, [openCloseContact]);

  useEffect(() => {
    if (openCloseContact !== undefined && openCloseContact !== null && !open)
      setOpenCloseContact(open);
  }, [open]);

  const [page, setPage] = useState({});
  const [loading, setLoading] = useState({});
  const [pageMedia, setPageMedia] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "contact"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  return (
    <header className="bg-white border-b">
      <nav
        className="container mx-auto flex items-center justify-between py-4"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <NavLink to={WEB_ROUTES.HOME} className="inline-block w-32 h-auto">
            <span className="sr-only">Mokoni Investment Diversities</span>
            <img
              src="/images/midiversities-logo.jpg"
              alt="Mokoni Investment Diversities Logo"
            />
          </NavLink>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          <NavLink
            to={WEB_ROUTES.HOME}
            className="font-semibold leading-6 text-[#21356a] uppercase"
          >
            Home
            <div className={``}></div>
            {WEB_ROUTES.HOME === location.pathname && (
              <div className={``}></div>
            )}
          </NavLink>

          <NavLink
            to={WEB_ROUTES.ABOUT}
            className="font-semibold leading-6 text-[#21356a] uppercase"
          >
            About
            <div className={``}></div>
            {WEB_ROUTES.ABOUT === location.pathname && (
              <div className={``}></div>
            )}
          </NavLink>

          <NavLink
            to={WEB_ROUTES.TEAM}
            className="font-semibold leading-6 text-[#21356a] uppercase"
          >
            Team
            <div className={``}></div>
            {WEB_ROUTES.TEAM === location.pathname && (
              <div className={``}></div>
            )}
          </NavLink>

          <NavLink
            to={WEB_ROUTES.SERVICES}
            className="font-semibold leading-6 text-[#21356a] uppercase"
          >
            Services
            <div className={``}></div>
            {WEB_ROUTES.SERVICES === location.pathname && (
              <div className={``}></div>
            )}
          </NavLink>

          <NavLink
            to={WEB_ROUTES.CONTACT}
            className="font-semibold leading-6 text-[#21356a] uppercase"
          >
            Contact us
            <div className={``}></div>
            {WEB_ROUTES.CONTACT === location.pathname && (
              <div className={``}></div>
            )}
          </NavLink>
        </div>
      </nav>

      <div className="lg:hidden" role="dialog" aria-modal="true">
        <div className="fixed inset-0 z-10"></div>
        <div className="fixed inset-y-0 right-0 z-10 flex w-full flex-col justify-between overflow-y-auto bg-white sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="p-6">
            <div className="flex items-center justify-between">
              <NavLink
                to={WEB_ROUTES.HOME}
                className="-m-1.5 p-1.5 inline-block w-40 h-auto"
              >
                <span className="sr-only">Mokoni Investment Diversities</span>
                <img
                  src="/images/mokoni-logo-colour.svg"
                  alt="Mokoni Investment Diversities Logo"
                />
              </NavLink>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">Close menu</span>
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <NavLink to={WEB_ROUTES.HOME} className="nav-link-item group">
                    Home
                    <div
                      className={`group -mx-3 flex items-center gap-x-6 rounded-lg p-3 text-base font-semibold leading-7 text-[#21356a] hover:bg-gray-50`}
                    ></div>
                    {WEB_ROUTES.HOME === location.pathname && (
                      <div className={``}></div>
                    )}
                  </NavLink>

                  <NavLink
                    to={WEB_ROUTES.ABOUT}
                    className="nav-link-item group"
                  >
                    About
                    <div
                      className={`group -mx-3 flex items-center gap-x-6 rounded-lg p-3 text-base font-semibold leading-7 text-[#21356a] hover:bg-gray-50`}
                    ></div>
                    {WEB_ROUTES.ABOUT === location.pathname && (
                      <div className={``}></div>
                    )}
                  </NavLink>

                  <NavLink to={WEB_ROUTES.TEAM} className="nav-link-item group">
                    Team
                    <div
                      className={`group -mx-3 flex items-center gap-x-6 rounded-lg p-3 text-base font-semibold leading-7 text-[#21356a] hover:bg-gray-50`}
                    ></div>
                    {WEB_ROUTES.TEAM === location.pathname && (
                      <div className={``}></div>
                    )}
                  </NavLink>

                  <NavLink
                    to={WEB_ROUTES.SERVICES}
                    className="nav-link-item group"
                  >
                    Services
                    <div
                      className={`group -mx-3 flex items-center gap-x-6 rounded-lg p-3 text-base font-semibold leading-7 text-[#21356a] hover:bg-gray-50`}
                    ></div>
                    {WEB_ROUTES.SERVICES === location.pathname && (
                      <div className={``}></div>
                    )}
                  </NavLink>

                  <NavLink
                    to={WEB_ROUTES.CONTACT}
                    className="nav-link-item group"
                  >
                    Contact us
                    <div
                      className={`group -mx-3 flex items-center gap-x-6 rounded-lg p-3 text-base font-semibold leading-7 text-[#21356a] hover:bg-gray-50`}
                    ></div>
                    {WEB_ROUTES.CONTACT === location.pathname && (
                      <div className={``}></div>
                    )}
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
