import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import { AppLoader } from "../components/app-loader";
import { WEB_ROUTES } from "../routes/web-routes";
import ServiceItem from "../components/service-item";
import FAQItem from "../components/faq-item";

export default function Services() {
  const [services, setServices] = useState([]);
  const [page, setPage] = useState({});
  const [loading, setLoading] = useState({});
  const [pageMedia, setPageMedia] = useState([]);
  const [servicesMedia, setServicesMedia] = useState([]);

  const whyPartner = [
    {
      title: "Attitude",
      description:
        "We maintain the best attitude towards work and clients, fostering positive relationships and a collaborative environment.",
    },
    {
      title: "Service Delivery",
      description:
        "Our commitment to service delivery is unwavering; our priority is ensuring that clients receive the utmost satisfaction.",
    },
    {
      title: "Experience",
      description:
        "With years of accumulated knowledge, we bring expertise and reliability to every project and interaction.",
    },
    {
      title: "Our Team",
      description:
        "Comprising industry-leading professionals dedicated to excellence, our team makes us stand out in the competitive landscape.",
    },
    {
      title: "Client Satisfaction",
      description:
        "We genuinely care about our client's satisfaction, finding joy in exceeding their expectations and fostering long-term partnerships.",
    },
    {
      title: "Competitive Edge",
      description:
        "We thrive on competition, constantly pushing ourselves to innovate and deliver superior solutions.",
    },
  ];

  useEffect(() => {
    setLoading(true);
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "services"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });

        //Get services
        axios
          .get(
            CONTENT_ROUTES.SERVICES(
              "?order_by=menu_order&order=asc&per_page=100"
            )
          )
          .then((res) => {
            setServices(res.data);

            //Get services media
            let media = [];

            res.data?.map((service, i) => {
              if (service?.featured_media)
                axios
                  .get(CONTENT_ROUTES.MEDIA_BY_ID(service?.featured_media))
                  .then((res) => {
                    media[i] = res.data;
                    setServicesMedia((prevState) => [...prevState, media[i]]);
                  });
              return service?.featured_media;
            });
          })
          .catch((err) => {})
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  useEffect(() => {
    setLoading(true);

    //Get team
    axios
      .get(
        CONTENT_ROUTES.SERVICES("?order_by=menu_order&order=asc&per_page=100")
      )
      .then((res) => {
        setServices(res.data);

        //Get services media
        let media = [];

        res.data?.map((service, i) => {
          if (service?.featured_media)
            axios
              .get(CONTENT_ROUTES.MEDIA_BY_ID(service?.featured_media))
              .then((res) => {
                media[i] = res.data;
                setServicesMedia((prevState) => [...prevState, media[i]]);
              });
          return service?.featured_media;
        });
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Header />
      <main>
        {Object.keys(page).length ? (
          <section className="bg-white pt-16 pb-8">
            <div className="container mx-auto md:px-0 px-8">
              <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                <div>
                  <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
                    <h1 className="pb-5 text-5xl font-semibold">
                      {page?.title?.rendered}
                    </h1>

                    <div
                      dangerouslySetInnerHTML={{
                        __html: page?.content?.rendered,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <AppLoader />
        )}

        <section className="pb-16">
          <div className="container mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-4 rounded-lg divide-x-2 divide-gray-950 border-2 border-gray-950">
              {services.map((service, i) => (
                <Fragment key={i}>
                  <ServiceItem
                    service={service}
                    serviceMedia={servicesMedia?.find(
                      (media) => media.id === service?.featured_media
                    )}
                  />
                </Fragment>
              ))}
            </div>
          </div>
        </section>

        <section className="pb-20">
          <div className="container mx-auto">
            <div className="grid grid-cols-1 gap-12 md:grid-cols-2 lg:gap-20">
              <div>
                <div className="mb-6">
                  <h2 className="text-4xl font-bold tracking-tight text-gray-900">
                    Why partner with us?
                  </h2>
                </div>

                <div className="bg-white">
                  <div className="mx-auto max-w-7xl">
                    <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                      {whyPartner.map((faq, i) => (
                        <Fragment key={i}>
                          <FAQItem faq={faq} />
                        </Fragment>
                      ))}
                    </div>
                  </div>
                </div>

                <NavLink
                  to={WEB_ROUTES.CONTACT}
                  className="bg-[#21356a] px-5 py-2 text-white hover:bg-[#00bbe3] inline-block rounded-full mt-10"
                >
                  Contact us now
                </NavLink>
              </div>
              <div className="overflow-hidden">
                <img
                  src={
                    pageMedia?.find(
                      (media) => media?.id === page?.featured_media
                    )?.source_url
                  }
                  alt={page.title?.rendered}
                  width="600"
                  height="400"
                  className="h-auto w-full"
                />
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
