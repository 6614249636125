export const WEB_ROUTES = {
  HOME: `/`,
  ABOUT: `/about`,
  CONTACT: `/contact`,
  TEAM: `/team`,
  TEAM_JEFFREY: `/team/jeffrey-makolwane`,
  TEAM_SALAMINA: `/team/salamina-mamabolo`,
  TEAM_LESHORO: `/team/leshoro-kgaugelo-maripane/`,
  SERVICES: `/services`,
  SERVICES_TAXATION: `/services/taxation-services`,
  SERVICES_ACCOUNTING: `/services/accounting-services`,
  SERVICES_ADVISORY: `/services/advisory-services`,
  SERVICES_TRAINING: `/services/training-and-accreditation-services`,
  NEWS: `/news`,
  NEWS_BHP_BILLITON: `/news/sakhiwo-completes-the-bhp-billiton-paediatric-centre`,
  NEWS_CECILIA_MAKIWANE: `/news/the-upgrade-of-the-cecilia-makiwane-hospital-in-mdantsane`,
  NEWS_AFRICA: `/news/sakhiwo-projects-throughout-the-rest-of-africa`,
  LEGAL: `/legal`,
  TERMS: `/legal/terms-of-use`,
  PRIVACY_POLICY: `/legal/privacy-policy`,
  NOT_FOUND: `/404`,
};
