import { useState, useEffect, useContext } from "react";
import { useMatch } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import { CONTENT_ROUTES } from "../routes/content-routes";
import { OpenCloseContactContext } from "../contexts/contact";
import Header from "../components/header";
import Footer from "../components/footer";
import axios from "axios";

export default function ServicesSingle() {
  const [service, setService] = useState({});
  let { setOpenCloseContact } = useContext(OpenCloseContactContext);
  const singleService = useMatch(WEB_ROUTES.SERVICES + "/:service");
  const [loading, setLoading] = useState({});
  const [page, setPage] = useState({});
  const [pageMedia, setPageMedia] = useState([]);
  const [serviceMedia, setServiceMedia] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "services"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });

        //Get service
        axios
          .get(
            CONTENT_ROUTES.SERVICES("?slug=" + singleService?.params?.service)
          )
          .then((res) => {
            let serviceResponse = res.data?.length ? res.data[0] : {};
            setService(serviceResponse);

            //Get service media
            axios
              .get(CONTENT_ROUTES.MEDIA_BY_PAGE(serviceResponse?.id))
              .then((res) => {
                setServiceMedia(res.data);
              });
          })
          .catch((err) => {})
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, [singleService]);

  return (
    <>
      <Header />
      <main>
        <div className="container mx-auto border-b border-gray-200 md:px-0 px-8md:px-0 px-8"></div>

        <section className="bg-white pt-16 pb-8">
          <div className="container mx-auto md:px-0 px-8">
            <div className="text-base leading-7 text-gray-700 w-full">
              <h1 className="pb-5 text-5xl font-semibold">
                {service?.title?.rendered}
              </h1>
            </div>
          </div>
        </section>

        <section className="bg-white pb-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-16 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div>
                <img
                  className="h-full w-full object-cover"
                  src={
                    serviceMedia?.find(
                      (media) => media?.id === service?.featured_media
                    )?.source_url
                  }
                  alt={service?.title?.rendered}
                />
              </div>
              <div>
                <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
                  <div className="max-w-xl">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: service?.content?.rendered?.replace(
                          /<p.*?>/g,
                          "<p class='mb-5'>"
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
