import { useState } from "react";

export default function FAQItem({ faq }) {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <>
      <dl className="py-4">
        <dt>
          <button
            type="button"
            className="flex w-full items-start justify-between text-left text-gray-900"
            aria-controls="faq-0"
            aria-expanded="false"
            onClick={() => setCollapsed(!collapsed)}
          >
            <span className="font-semibold">{faq?.title}</span>
            <span className="ml-6 flex h-7 items-center text-[#00bbe3]">
              {collapsed ? (
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M18 12H6"
                  />
                </svg>
              ) : (
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6v12m6-6H6"
                  />
                </svg>
              )}
            </span>
          </button>
        </dt>
        <dd className="pr-12" id="faq-0">
          <p className={`${collapsed ? `visible` : `hidden`} text-gray-600`}>
            {faq?.description}
          </p>
        </dd>
      </dl>
    </>
  );
}
